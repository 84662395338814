import { Helmet } from 'react-helmet'
import React from 'react'

export const GoogleTag = () => {
    const script = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-836018306');`

    return (
        <Helmet>
            {/* Google tag (gtag.js) */}
            <script async src='https://www.googletagmanager.com/gtag/js?id=AW-836018306'></script>
            <script>{script}</script>
        </Helmet>
    )
}
